@import '../styles/const';

.people {
  margin: calcVH(30) 0 calcVH(100) 0;

  @media screen and (orientation:landscape) {
    margin: calcVH(40) 0 calcVH(180) 0;
  }

  &__title {
    color: $color-light;
    font-weight: 700;
    font-size: calcVH(25);
    line-height: calcVH(40);
    text-transform: uppercase;
    margin-bottom: calcVH(16);

    @media screen and (orientation:landscape) {
      font-size: calcVH(32);
      line-height: calcVH(56);
    }
  }
}

.avatars {
  &__list {
    display: flex;
    align-items: center;
  }


  & .active {
    opacity: 1;
  }

  &__item {
    border-radius: 50%;
    background-color: $color-light;
    border: 1px solid $color-light;
    overflow: hidden;
    width: calcVH(60);
    height: calcVH(60);
    margin-left: calcVH(-10);
    object-fit: cover;

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      opacity: 0;
    }

    @media screen and (orientation:landscape) {
      width: calcVH(80);
      height: calcVH(80);
      margin-left: calcVH(-10);
    }
  }
}