@import '../styles/const';

.section {

  @media screen and (orientation:landscape) {
    margin-bottom: calcVH(100);
  }
}

.reception {
  &__title {
    font-weight: 900;
    font-size: calcVH(40);
    line-height: 130%;
    color: $color-dark;

    @media screen and (orientation:landscape) {
      font-size: calcVH(56);
      line-height: 80%;
    }
  }
  &__company-name {
    word-break: break-all;
  }
}

.apps {
  margin-bottom: calcVH(50);

  &__qr {
    display: flex;
    align-items: center;
    margin: calcVH(30) 0 calcVH(40) 0;

    @media screen and (orientation:landscape) {
      margin: calcVH(40) 0 calcVH(50) 0;
    }
  }

  &__qr--img {
    width: calcVH(97);
    height: calcVH(96);

    @media screen and (orientation:landscape) {
      width: calcVH(80);
      height: calcVH(80);
    }
  }

  &__list {
    display: flex;
    align-items: center;
  }

  &__link {
    &:first-child {
      margin-right: calcVH(24);
    }
  }

  &__title {
    text-transform: uppercase;
    font-weight: 600;
    font-size: calcVH(20);
    line-height: calcVH(40);
    color: $color-dark;
    margin-left: calcVH(24);

    @media screen and (orientation:landscape) {
      font-size: calcVH(28);
      line-height: calcVH(42);
    }
  }

  &__google {
    width: calcVH(176);
    height: calcVH(52);
  }

  &__apple {
    width: calcVH(161);
    height: calcVH(52);
  }
}

