@import '../styles/const';

.meeting-room {
  &__title {
    font-weight: 600;
    font-size: calcVH(24);
    line-height: 100%;
    text-transform: uppercase;
    color: $color-light;
    margin-bottom: calcVH(24);

    @media screen and (orientation:landscape) {
      font-size: calcVH(32);
      line-height: 130%;
      margin-bottom: calcVH(36);
    }
  }

  &__name {
    font-weight: 800;
    font-size: calcVH(40);
    line-height: 100%;
    color: $color-light;

    @media screen and (orientation:landscape) {
      font-size: calcVH(56);
      line-height: 130%;
    }
  }
}

.helpdesk {
  margin-bottom: calcVH(40);

  @media screen and (orientation:landscape) {
    margin-bottom: calcVH(70);
  }

  &__list {
    color: $color-light;
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: calcVH(20);
    line-height: calcVH(30);
    text-transform: uppercase;

    @media screen and (orientation:landscape) {
      font-size: calcVH(28);
      line-height: calcVH(42);
    }
  }

  &__qr {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: calcVH(10);
    width: calcVH(80);
    height: calcVH(80);
    background: $color-light;
    border-radius: 20px;
    margin-right: calcVH(32);
    overflow: hidden;

    img {
      max-width: calcVH(80);
      height: calcVH(80);
    }
  }
}
