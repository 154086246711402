@import '../styles/const';

.today {
  text-transform: uppercase;

  &--light {
    color: $color-light;
    font-weight: 800;
    font-size: calcVH(20);
    line-height: calcVH(40);

    @media screen and (orientation:landscape) {
      font-size: calcVH(20);
      line-height: calcVH(40);
    }
  }

  &--dark {
    font-weight: 800;
    font-size: calcVH(24);
    line-height: calcVH(40);
    margin-bottom: calcVH(16);

    @media screen and (orientation:landscape) {
      font-size: calcVH(32);
      line-height: calcVH(56);
      margin-bottom: calcVH(24);
    }
  }
}
