@import '../styles/const';

.logo {
  margin-bottom: calcVH(40);

  @media screen and (orientation:landscape) {
    margin-bottom: calcVH(50);
  }

  &__img {
    max-height: calcVH(40);
    max-width: calcVH(300);

    @media screen and (orientation:landscape) {
      max-height: calcVH(80);
      max-width: calcVH(500);
    }
  }
}
