@import '../styles/const';

.wifi {
  font-weight: 600;
  color: $color-light;
  font-size: calcVH(20);
  line-height: calcVH(32);

  @media screen and (orientation:landscape) {
    font-size: calcVH(28);
    line-height: calcVH(42);
  }

  &__container {
    display: block;

    @media screen and (orientation:landscape) {
      display: flex;
      align-items: center;
    }
  }

  &__list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: calcVH(20);

    @media screen and (orientation:landscape) {
      justify-content: flex-start;
      margin-bottom: 0;
    }
  }

  &__title {
    text-transform: uppercase;
    position: relative;
    min-width: calcVH(120);
    margin-right: calcVH(16);

    @media screen and (orientation:landscape) {
      min-width: calcVH(155);
    }

    &:after {
      @media screen and (orientation:landscape) {
        content: ' ';
        position: absolute;
        right: calcVH(-8);
        bottom: calcVH(11);
        width: calcVH(1);
        height: calcVH(16);
        background-color: $color-light;
      }
    }
  }

  &__password {
    word-break: break-all;
  }

  &__qr {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: calcVH(10);
    width: calcVH(80);
    height: calcVH(80);
    background: $color-light;
    border-radius: 20px;
    overflow: hidden;

    img {
      max-width: calcVH(80);
      height: calcVH(80);
    }

    &--right {
      order: 2;
      margin-top: calcVH(20);
      display: flex;
      justify-content: center;

      @media screen and (orientation:landscape) {
        display: block;
        margin-left: calcVH(32);
      }
    }

    &--left {
      order: 1;
      margin-top: calcVH(20);
      display: flex;
      justify-content: center;

      @media screen and (orientation:landscape) {
        display: block;
        margin-right: calcVH(32);
      }
    }

  }


}
