@import '../styles/const';

.container {
  display: block;

  @media screen and (orientation:landscape) {
    display: flex;
    align-items: center;
  }
}

.row {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-repeat: no-repeat;
  background-size: cover;
  padding: calcVH(30);

  @media screen and (orientation:landscape) {
    padding: calcVH(40);
    height: 100vh;
    &:first-child {
      background-color: $color-light;
      width: 40%;
    }

    &:last-child {
      width: 60%;
    }
  }
}
